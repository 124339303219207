.toggle{
    display: flex;
    justify-content:space-between;
    border: 3px solid #14d300;
    border-radius: 1rem;
    position: relative;
    padding: 2px;
    cursor: pointer;

}
.toggle>*{
    width: 1rem;
    height: 1rem;
    color: #14d300;
}
.t-button{
    border-radius: 100%;
    background: #14d300;
    position: absolute;
}
